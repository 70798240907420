import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WineBarIcon from '@mui/icons-material/WineBar';
import AppleIcon from '@mui/icons-material/Apple';
import CookieIcon from '@mui/icons-material/Cookie';
import TapasIcon from '@mui/icons-material/Tapas';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import Badge from '@mui/material/Badge';
import DiningIcon from '@mui/icons-material/Dining';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import PsychologyIcon from '@mui/icons-material/Psychology';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CasinoIcon from '@mui/icons-material/Casino';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

export default function Online() {
  const items = useSelector((state) => state.plate.items);  
  const categories = useSelector((state) => state.products.categories)

  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
            {/* <Link  to='/category/Mechanics' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <PsychologyIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Book Mechanic`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/category/Towing Service' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <RvHookupIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Towing Service`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Car Auction' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <NoCrashIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Car Auction`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Cars' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CasinoIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Buy Cars`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/category/Auto Parts' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <BuildCircleIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Auto Parts`} />
                  </ListItemButton>
              </ListItem>
            </Link>
             */}
                     
          </List>
          <Divider />
          <List>

          <Link  to='/plate-content' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Badge badgeContent={items.length} color="error">
                      <CardGiftcardIcon />
                  </Badge>
                  </ListItemIcon>
                  <ListItemText  primary={`Gift Box`} />
                  </ListItemButton>
              </ListItem>
            </Link>

           

            <Link  to='/dining' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <CasinoIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Playground`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Wallet`} />
                    </ListItemButton>
                </ListItem>
              </Link>


              <Link  to='/withdrawals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <MoneyOffIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Withdrawals`} />
                    </ListItemButton>
                </ListItem>
              </Link>
          
            
              <Divider /> 
              {categories.length > 0 &&
            categories.map(category =>(
              <Fragment key={category.id}>
              <Link  to={`/category/${category.name}`} component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    {/* <ListItemIcon>
                    <CarCrashIcon/>
                    </ListItemIcon> */}
                    <ListItemText  primary={category.name} />
                    </ListItemButton>
                </ListItem>
              </Link>
              </Fragment>

            ))
            }
            <Divider/>

            <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Profile`} />
                  </ListItemButton>
              </ListItem>
            </Link>
            

           
            
            
            

            <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText  primary={`Referral`} />
                    </ListItemButton>
                </ListItem>
              </Link>
            <Link  to=''  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Live Support`} />
                </ListItemButton>
            </ListItem>
          </Link>

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <SettingsIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}