import axios from "axios";

axios.defaults.withCredentials = true;

export default axios.create({
  
  baseURL: 'https://api.pchwins.com',
  headers: {
    "Content-type": "application/json",
    withCredentials: true
  }
});