import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Plate from '../Plates/Plate';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import PinDropIcon from '@mui/icons-material/PinDrop';
import VerifiedIcon from '@mui/icons-material/Verified';

const TodayBreakFast = () => {
    const scrolltoTop = () => window.scrollTo(0, 0);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.breakfasts);

  return (
    <div>
    <Typography ml={3} mt={4} sx={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: 20, textAlign: 'left'  }} gutterBottom variant="h6" component="div">
        Most Purchased Ticket
    </Typography>
    <Typography ml={3} mt={-1} sx={{textAlign: 'left'}} variant="caption" component="div" gutterBottom>
        purchased by over one Million users
    </Typography>
    {products.length > 0 &&
    <Grid container px={2} mt={1}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {products.map(product=>(
        <Grid key={product.id} item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link onClick={()=> {dispatch(setProduct(product.product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia
                component="img"
                sx={{ width: '100%' }}
                
                image={product.product.product_image}
                alt={product.product.description}
            />
            <Box mt={-1} sx={{display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Avatar sx={{marginLeft: '3px', boxShadow: '2px solid gray', marginTop: '-20px', width: 56, height: 56}} >
          <VerifiedIcon color='primary'/>
            </Avatar>
          
              <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}  spacing={1}>
                <Rating name="half-rating" size="small" defaultValue={4.5} precision={0.5} readOnly />
              </Stack>
            
            </Box>

            <CardContent sx={{padding: 0, marginTop: '8px'}}>
          
            <Typography sx={{  textTransform: 'uppercase'  }}  gutterBottom variant="subtitle2" >
                {product.product.name}
            </Typography>

            <ListItem disablePadding>
                <ListItemButton>
                <ListItemText sx={{width: '100%'}} primary={`Description`} secondary={product.product.description} />
                </ListItemButton>
            </ListItem>

            {/* <Typography variant="caption" maxLength={10} textAlign="left" px={1} display="block" gutterBottom>
            {product.product.short_description}
            </Typography> */}
            
            </CardContent>
            <Typography color="secondary" sx={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1   }} gutterBottom variant="button" component="span">
            {formatPrice(product.product.unit_price)}
            </Typography>
          </Link>
            <CardActions  sx={{justifyContent: 'center', marginTop: '0px', bottom: 0}}>
            <Plate product={product.product} caption="Get Ticket"/>
            </CardActions>    
        </Card>
    </Grid>
      ))}
</Grid>
    }
    
</div>
  )
}

export default TodayBreakFast
